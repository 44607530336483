import LayoutType from '@/shared/types/layouts';

export enum DisplayType {
  MANAGER = 'manager',
  OPERATOR = 'operator',
}

export interface RequestLogin {
  pin: string;
  displayType: DisplayType;
  organizationName: string;
  displayCode?: string;
}

export interface ResponseLogin {
  accessToken: string;
  teamReference: string;
  languageCode: string;
  publicDisplayLayout: LayoutType;
  organizationName: string;
  loginWithoutPin?: boolean;
  publicDisplayReference: string;
}
