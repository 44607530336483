import axios from 'axios';
import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { DisplayType, RequestLogin, ResponseLogin } from '@/shared/types/token';
import useVerifyDevice from '@/composables/useVerifyDevice/useVerifyDevice';
import { ResponsePostHog } from '@/shared/commons';
import i18n from '@/plugins/i18n/i18n';
import LayoutType from '@/shared/types/layouts';

const useTokenStore = defineStore('token', () => {
  const settings: ResponseLogin = reactive({
    accessToken: '',
    teamReference: '',
    languageCode: '',
    organizationName: '',
    loginWithoutPin: false,
    publicDisplayReference: '',
    publicDisplayLayout: LayoutType.ALL_CHALLENGES,
  });

  const { isTablet } = useVerifyDevice();
  const isLoading = ref(false);
  const device = isTablet ? 'ipad' : undefined;

  function $reset() {
    settings.accessToken = '';
    settings.teamReference = '';
    settings.languageCode = '';
    settings.organizationName = '';
    settings.loginWithoutPin = false;
    settings.publicDisplayReference = '';
    settings.publicDisplayLayout = LayoutType.ALL_CHALLENGES;
  }

  function getPostHogKey(): Promise<ResponsePostHog> {
    const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/public-displays/configs`;
    return axios.get(apiUrl, {
      headers: {
        'content-type': 'application/json',
        'Public-Display-Authorization': `Bearer ${settings.accessToken}`,
      },
    });
  }

  async function isAuthenticatedWithoutPin(
    organizationName: string,
  ): Promise<void> {
    isLoading.value = true;
    settings.organizationName = organizationName;

    await axios({
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
      url: `${process.env.VUE_APP_API_BASE_URL}/management/public-displays/authentication/${organizationName}`,
    })
      .then((response) => {
        isLoading.value = false;
        const loginResponse: ResponseLogin = response.data;
        const {
          accessToken,
          loginWithoutPin,
          publicDisplayLayout,
          teamReference,
          publicDisplayReference,
          languageCode,
          organizationName: orgName,
        } = loginResponse;
        settings.loginWithoutPin = loginWithoutPin;
        settings.accessToken = accessToken;
        settings.publicDisplayLayout = publicDisplayLayout;
        settings.teamReference = teamReference;
        settings.languageCode = languageCode;
        settings.organizationName = orgName;
        settings.publicDisplayReference = publicDisplayReference;
        i18n.global.locale.value = settings.languageCode;
      })
      .catch(() => {
        isLoading.value = false;
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  async function getAuthenticationByDisplayCode(displayCode: string) {
    await axios({
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
      url: `${process.env.VUE_APP_API_BASE_URL}/management/public-displays/authentication?displayCode=${displayCode}`,
    })
      .then((response) => {
        isLoading.value = false;
        const loginResponse: ResponseLogin = response.data;
        const {
          accessToken,
          loginWithoutPin,
          publicDisplayLayout,
          teamReference,
          publicDisplayReference,
          languageCode,
          organizationName: orgName,
        } = loginResponse;
        settings.loginWithoutPin = loginWithoutPin;
        settings.accessToken = accessToken;
        settings.publicDisplayLayout = publicDisplayLayout;
        settings.teamReference = teamReference;
        settings.languageCode = languageCode;
        settings.organizationName = orgName;
        settings.publicDisplayReference = publicDisplayReference;
        i18n.global.locale.value = settings.languageCode;
      })
      .catch(() => {
        isLoading.value = false;
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  async function login(
    organizationName: string,
    pin?: string,
    displayCode?: string,
  ): Promise<void> {
    settings.organizationName = organizationName;
    const data: RequestLogin = {
      pin: pin || '',
      organizationName,
      displayType: DisplayType.OPERATOR,
      displayCode,
    };

    await axios({
      method: 'post',
      data,
      headers: {
        'content-type': 'application/json',
      },
      url: `${process.env.VUE_APP_API_BASE_URL}/management/public-displays/login`,
      params: { device },
    }).then((response) => {
      const loginResponse: ResponseLogin = response.data;
      const {
        accessToken,
        loginWithoutPin,
        publicDisplayLayout,
        teamReference,
        publicDisplayReference,
        languageCode,
        organizationName: orgName,
      } = loginResponse;
      settings.accessToken = accessToken;
      settings.loginWithoutPin = loginWithoutPin;
      settings.publicDisplayLayout = publicDisplayLayout;
      settings.teamReference = teamReference;
      settings.languageCode = languageCode;
      settings.organizationName = orgName;
      settings.publicDisplayReference = publicDisplayReference;
      i18n.global.locale.value = settings.languageCode;
    });
  }

  return {
    $reset,
    settings,
    login,
    getPostHogKey,
    isLoading,
    isAuthenticatedWithoutPin,
    getAuthenticationByDisplayCode,
  };
});

export default useTokenStore;
