import { defineStore } from 'pinia';
import { ref } from 'vue';
import { HttpStatus } from '@/shared/types/generic';

const useErrorStore = defineStore('error', () => {
  const errorCode = ref<HttpStatus | undefined>(undefined);

  function setErrorCode(code?: HttpStatus) {
    errorCode.value = code;
  }

  function $reset() {
    errorCode.value = undefined;
  }

  return { errorCode, setErrorCode, $reset };
});

export default useErrorStore;
