import { ApiResponse } from '@/shared/types/generic';
import { KPI } from '@/shared/types/kpi';

export default (): ApiResponse<KPI[]> => ({
  metadata: {
    totalItems: 4,
    lastUpdated: 1734972583723,
  },
  results: [
    {
      kpi: 'TOTAL_PURCHASE',
      reference: 'b6321a37-ec23-402b-aec3-7a24a6c3e264',
      label: 'total.purchases',
      counter: 99999,
      order: 1,
    },
    {
      kpi: 'TOTAL_LOGINS',
      reference: 'f4c55e42-a9df-4a9c-825b-adf6b5e911db',
      label: 'total.logins',
      counter: 123,
      order: 2,
    },
    {
      kpi: 'TOTAL_UNLOCKED_ACHIEVEMENTS',
      reference: 'ae8655cb-f9c4-419d-ba9b-d6d110df3e33',
      label: 'achievement.title',
      counter: 12312321312,
      order: 3,
    },
    {
      kpi: 'TOTAL_KUDOS_EXCHANGED',
      reference: '14ce1a6c-4407-49dc-9481-fd2d70f1e5c9',
      label: 'kudos.exchanged',
      counter: 0,
      order: 4,
    },
  ],
});
