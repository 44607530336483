import { Base } from './generic';
import { Metadata } from './utils';

export enum AchievementType {
  KUDOS_RECEIVED = 'KUDOS_RECEIVED',
  ACHIEVEMENTS_UNLOCKED = 'ACHIEVEMENTS_UNLOCKED',
  KUDOS_GIVEN = 'KUDOS_GIVEN',
  STORE_PURCHASE = 'STORE_PURCHASE',
}

export enum AchievementName {
  KUDOS_RECEIVED = 'kudos.received',
  ACHIEVEMENTS_UNLOCKED = 'achievements.top',
  KUDOS_GIVEN = 'kudos.given',
  STORE_PURCHASE = 'store.purchase',
}

export interface AchievementsLeaderboardItem {
  username: string;
  value: number;
  avatar: string;
}

export interface Achievement extends Base {
  firstPlace?: AchievementsLeaderboardItem;
  secondPlace?: AchievementsLeaderboardItem;
  thirdPlace?: AchievementsLeaderboardItem;
  kpi: AchievementType;
  label: AchievementName;
  noData: boolean;
  order: number;
  icon: string;
}

export interface AchievementsResponse {
  metadata: Metadata;
  results: Achievement[];
}
