import { GREEN_500, BLACK } from '@/shared/constants/colors';
import { ImageResponse, ThemesResponse } from '@/shared/types/themes';

function getRandom(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
// '/img/vaibe-logo.svg'
// 'https://github.com/favicon.ico'
export const mockThemes = (): Array<ThemesResponse> => {
  return [
    {
      logo: {
        default: false,
        description: '',
        name: '',
        reference: '',
        url: '/img/vaibeLogos.svg',
      },
      name: '',
      opacity: 0,
      favicon: {
        default: false,
        description: '',
        name: '',
        reference: '',
        url: 'https://github.com/favicon.ico',
      },
      publicDisplayBackground: {
        default: false,
        description: '',
        name: '',
        reference: '',
        url: '/img/BGCocaCola.svg',
      },
      reference: '',
      primaryColor: {
        colorHex: BLACK,
        colorRgb: '0, 0, 0',
      },
      secondaryColor: {
        colorHex: GREEN_500,
        colorRgb: GREEN_500,
      },
      publicDisplayColor: {
        colorHex: '#F40000',
        colorRgb: '244, 0, 0',
      },
      publicDisplayWidgetTwoBackground: {} as ImageResponse,
      publicDisplayWidgetOneBackground: {
        default: false,
        description: '',
        name: '',
        reference: '',
        url: '/img/cocaCola-challenge-background.png',
      } as ImageResponse,
    },
  ];
};

export const getTheme = (): ThemesResponse => {
  const randomValue = getRandom(0, mockThemes().length - 1);
  return mockThemes()[randomValue];
};
