import LayoutType from '@/shared/types/layouts';
import { ResponseLogin } from '@/shared/types/token';

export default (): ResponseLogin => ({
  publicDisplayLayout: LayoutType.CUSTOMIZABLE,
  accessToken: 'lskdjfsdf-sdjflskdjfds-fksdjflsj-ssioaaiou',
  teamReference: '2222-22344-44555',
  languageCode: 'pt-PT',
  organizationName: 'Joana Org',
  publicDisplayReference: 'd099cdc0-b1e4-f386-5201-2aa3cd9408f1',
  loginWithoutPin: true,
});
