export enum HttpStatus {
  TEST = -1,
  UNAUTHORIZED_USER = 401,
  FORBIDDEN = 403,
  NO_CONNECTION = 502,
  SERVER_MAINTENANCE = 503,
  INACTIVE_USER = 700,
}

export enum Locale {
  PT = 'pt-PT',
  US = 'en-US',
}

export interface ChartPoint {
  x: number;
  y: number;
}

export enum TypeProgressBar {
  PRIMARY = 'PRIMARY',
  WARNING = 'WARNING',
  SECONDARY = 'SECONDARY',
  NEGATIVE = 'NEGATIVE',
  WINNER = 'WINNER',
}

export enum RouteAuthenticationRequirement {
  AUTHENTICATED,
  UNAUTHENTICATED,
  UNIVERSAL,
}

export enum GraphType {
  TEAM = 'TEAM',
  GENERIC = 'GENERIC',
}

export interface ResponseMetadata {
  totalItems?: number;
  lastUpdated?: number;
}

export interface ApiResponse<T> {
  metadata: ResponseMetadata;
  results: T;
}

export enum StatusStaticReference {
  ACTIVE = 'S_1',
  DELETED = 'S_2',
  SCHEDULED = 'S_3',
  LIVE = 'S_4',
  COMPLETED = 'S_5',
  NOT_STARTED = 'S_6',
  READY = 'S_7',
  ON_GOING = 'S_8',
  CALCULATING_RESULTS = 'S_9',
  DONE = 'S_10',
  NOT_CLAIMED = 'S_11',
  CLAIMED = 'S_12',
  INACTIVE = 'S_13',
  DELIVERY = 'S_14',
  NOT_DELIVERY = 'S_15',
  CANCELED = 'S_16',
}

export enum DatePeriod {
  ZERO = 0,
  SECOND = 1000,
  MINUTE = 60000,
  HOUR = 3600000,
  DAY = 86400000,
  WEEK = 604800000,
  MONTH = 2592000000,
  QUARTER = 7776000000,
  YEAR = 31104000000,
}

export interface Base {
  reference: string;
}

export interface TranslationParts {
  key: string;
  parameters: Record<string, unknown>;
}
