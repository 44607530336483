import { dummyAvatar } from '@/shared/constants/icons';
import {
  AchievementName,
  AchievementsResponse,
  AchievementType,
  Achievement,
} from '@/shared/types/achievements';
import {
  avidCompetitor,
  bigSpender,
  peoplePerson,
  vaibeRegular,
} from './iconAchievements';

export const createAchievement = (
  kpi = AchievementType.KUDOS_RECEIVED,
  label = AchievementName.KUDOS_RECEIVED,
  icon = avidCompetitor,
): Achievement => ({
  firstPlace: {
    username: 'John Doe',
    value: 100,
    avatar: dummyAvatar,
  },
  secondPlace: {
    username: 'Jane Doe',
    value: 90,
    avatar: dummyAvatar,
  },
  thirdPlace: {
    username: 'Jack Doe',
    value: 80,
    avatar: dummyAvatar,
  },
  kpi,
  reference: '1',
  label,
  noData: false,
  order: 0,
  icon,
});

export const mockAchievements: Achievement[] = [
  createAchievement(),
  createAchievement(
    AchievementType.KUDOS_GIVEN,
    AchievementName.KUDOS_GIVEN,
    bigSpender,
  ),
  createAchievement(
    AchievementType.ACHIEVEMENTS_UNLOCKED,
    AchievementName.ACHIEVEMENTS_UNLOCKED,
    peoplePerson,
  ),
  createAchievement(
    AchievementType.STORE_PURCHASE,
    AchievementName.STORE_PURCHASE,
    vaibeRegular,
  ),
];

export const getMockAchievementsResponse = (): AchievementsResponse => ({
  metadata: {
    totalItems: 4,
    lastUpdated: 1735554681820,
  },
  results: mockAchievements,
});
