import { createApp } from 'vue';
import { createPinia } from 'pinia';
import worker from './mocks/browser';
import router from './router';
import i18n from './plugins/i18n/i18n';
import App from './App.vue';
import '@/styles/scss/main.scss';
import '@/plugins/echarts/echarts';

const pinia = createPinia();

if (process.env.VUE_APP_USE_MOCK_SERVER === 'true') {
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

createApp(App).use(i18n).use(router).use(pinia).mount('#app');
