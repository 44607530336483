export const vaibeLogoWhite = '/img/vaibe-logo-white.svg';
export const vaibeLogoSmall = '/img/vaibe-logo-small.svg';
export const vaibeLogos = '/img/vaibeLogos.svg';
export const exampleType = '/img/exampleType.png';
export const exampleMode = '/img/exampleMode.png';
export const emptyState = '/img/emptyState.svg';
export const emptyStateBlue = '/img/emptyStateBlue.svg';
export const emptyStateGlobal = '/img/empty-state.svg';
export const spinner = '/img/spinner.svg';
export const backspace = '/img/backspace.svg';
export const noChallenges = '/img/noChallenges.png';
export const dumyChallengeBackground = '/img/challenge-background.png';
export const dummyAvatar = '/img/dummy-avatar.png';
export const goBack = '/img/go-back.svg';
export const notFoundBackground = 'img/not_found.png';
export const notFoundBackground2 = 'img/not_found2.svg';
